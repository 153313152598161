import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { IconColumn } from '../../models/iconColums';
import { MonthReport } from '../../models/monthReport.model';
import { TableColumn } from '../../models/tableColumn';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() tableColumn : TableColumn<any>[] = [];
  @Input() displayedColumns? : string[];
  @Input() tableData: MonthReport[] = [];
  @Input() tableIcon: IconColumn<any>[]=[];
  @Input() reload: boolean = false;
  @Input() errorKeyOnLoad?: string;
  @Input() showPagination = true;

  tableDataSource: MatTableDataSource<MonthReport>;

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatTable, {static: false}) table!: MatTable<any>;

  constructor(private snackBarService: SnackBarService) {
    this.tableDataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    if(this.paginator) {
      this.tableDataSource!.paginator = this.paginator;
    }

    if(this.sort) {
      this.tableDataSource!.sort = this.sort;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
      if(typeof changes['tableData'] !== 'undefined' && this.table) {
        this.tableDataSource.data = changes['tableData'].currentValue;
        this.table.renderRows();
      }
  }
}

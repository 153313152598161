export const JWT_TOKEN_NAME = "jwt-token";
export const JWT_TOKEN_EXPIREDATE = "jwt-token-expire-date";

export interface User {
    firstName?: string,
    lastName?: string,
}


export interface UserState {
    user: User | null;
    isLoggedIn: boolean;
}
<button mat-button class=" waves-effect waves-light btn-large icon-file-button">
    <div class="file-icon">
        <div>
            <img src='{{"assets/images/icons8-"+translateTypeToIcon(type)+"-24.png"}}'>
        </div>
        <div>
            {{name}}
        </div>
    </div>

</button>
<div class="main-container" *ngIf="userStatus.isLoggedIn">
  <mat-toolbar class="navbar" color="primary">
    <div class="app-title"><img src="assets/images/logo.svg"/><span>Consuntivazione lavori</span></div>
    <ng-container [ngSwitch]="userStatus.isLoggedIn">
      <div *ngSwitchCase="false">Utente non loggato</div>
      <div *ngSwitchCase="true" class="user-info">
        <span>{{ userStatus.user!.firstName + " " + userStatus.user!.lastName }}</span>
        <span class="btn" (click)="logout()">Logout</span></div>
    </ng-container>
  </mat-toolbar>
  <app-title
    [title]="''"
    [buttons]="titleButtons"
    [options]="months"
    (selectionChange)="onSelectionChange($event)">
  </app-title>
    <app-table class="app-table"
      [tableColumn]="firstNReport"
      [displayedColumns]="displayedColumns"
      [tableData]="tableData"
      [reload]=" loading"
      >
  </app-table>
</div>
<div class="overlay-spinner" *ngIf="!userStatus.isLoggedIn">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  <span>Attendere prego, verrai rediretto alla pagina di login...</span>
</div>
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DateTime } from 'luxon';
import { FileSaverService } from 'ngx-filesaver';
import { finalize, tap } from 'rxjs';

import { UploadFileComponent } from './components/uploadFile/uploadFile.component';
import { ButtonTitle } from './models/buttonTitle';
import { FilterDTO, ReportQuery } from './models/FilterDTO.model';
import { MonthReport } from './models/monthReport.model';
import { MonthYearOption } from './models/selectOption.model';
import { UserState } from './models/state';
import { TableColumn } from './models/tableColumn';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'consuntivazione-lavori';
  userStatus: UserState = {isLoggedIn: false, user: null};


  titleButtons: ButtonTitle[] = [
    {
      lable: "Scarica report",
      actionButton: () => {
        this.downloadReport();

      }
    },
    {
      lable: "Carica file delle cessazioni",
      actionButton: () => {
        this.openUploadFileDialog();
      }
    }
  ];

  months: MonthYearOption[] = [];


  displayedColumns = [
    'codice_cmdb',
    'realta_aziendale',
    'tipo_sede',
    'indirizzo',
    'citta',
    'provincia',
    'classecommerciale_old',
    'hostname',
    'loopback',
    'attiva',
    'task_pending_cessazione',
    'data_cessazione',
    'classecommerciale',
    'tipo_lavorazione_mpls',
    'data_attivazione_mpls',
    'sdwan',
    'data_attivazione_sdwan',
    'internet',
    'tipo_lavorazione_internet',
    'classe_internet',
    'data_attivazione_internet'
  ];
  firstNReport: TableColumn<MonthReport>[] = [
    {
      title: 'Codice CMBD',
      columnDef: 'codice_cmdb',
      accessor: (data: MonthReport) => data.codice_cmdb,
    },
    {
      title: 'Realtà aziendale',
      columnDef: 'realta_aziendale',
      accessor: (data: MonthReport) => data.realta_aziendale,
    },
    {
      title: 'Tipo Sede',
      columnDef: 'tipo_sede',
      accessor: (data: MonthReport) => data.tipo_sede,
    },
    {
      title: 'Indirizzo',
      columnDef: 'indirizzo',
      accessor: (data: MonthReport) => data.indirizzo,
    },
    {
      title: 'Città',
      columnDef: 'citta',
      accessor: (data: MonthReport) => data.citta,
    },
    {
      title: 'Provincia',
      columnDef: 'provincia',
      accessor: (data: MonthReport) => data.provincia,
    },
    {
      title: 'Classe Accesso OLD',
      columnDef: 'classecommerciale_old',
      accessor: (data: MonthReport) => data.classecommerciale_old,
    },
    {
      title: 'Hostname1',
      columnDef: 'hostname',
      accessor: (data: MonthReport) => data.hostname,
    },
    {
      title: 'loopback',
      columnDef: 'loopback',
      accessor: (data: MonthReport) => data.loopback,
    },
    {
      title: 'Attiva/cessata',
      columnDef: 'attiva',
      accessor: (data: MonthReport) => data.attiva,
    },
    {
      title: 'Task pending cessazione',
      columnDef: 'task_pending_cessazione',
      accessor: (data: MonthReport) => data.task_pending_cessazione,
    },
    {
      title: 'data cessazione',
      columnDef: 'data_cessazione',
      accessor: (data: MonthReport) => {
        // 2023-06-16 02:00:00.000
        return data.data_cessazione ? DateTime.fromFormat(data.data_cessazione, 'yyyy-LL-dd HH:mm:ss.u').toLocaleString(DateTime.DATE_SHORT) : '-';
      }
    },
    {
      title: 'Classe Commerciale NEW',
      columnDef: 'classecommerciale',
      accessor: (data: MonthReport) => data.classecommerciale,
    },
    {
      title: 'Tipo lavorazione MPLS',
      columnDef: 'tipo_lavorazione_mpls',
      accessor: (data: MonthReport) => data.tipo_lavorazione_mpls,
    },
    {
      title: 'data MPLS',
      columnDef: 'data_attivazione_mpls',
      accessor: (data: MonthReport) => {
        if(data.data_attivazione_mpls){
          // const dateString = data.data_attivazione_mpls.substring(0, data.data_attivazione_mpls.indexOf("."));
          return DateTime.fromFormat(data.data_attivazione_mpls, 'yyyy-LL-dd HH:mm:ss.u').toLocaleString(DateTime.DATE_SHORT);
        }

        return '-';
      }
    },
    {
      title: 'SDWAN',
      columnDef: 'sdwan',
      accessor: (data: MonthReport) => data.sdwan,
    },
    {
      title: 'data SDWAN',
      columnDef: 'data_attivazione_sdwan',
      accessor: (data: MonthReport) =>  {
        return data.data_attivazione_sdwan ? DateTime.fromFormat(data.data_attivazione_sdwan, 'yyyy-LL-dd HH:mm:ss.u').toLocaleString(DateTime.DATE_SHORT) : '-';
      },
    },
    {
      title: 'Internet',
      columnDef: 'internet',
      accessor: (data: MonthReport) => data.internet,
    },
    {
      title: 'tipo lavorazione Internet',
      columnDef: 'tipo_lavorazione_internet',
      accessor: (data: MonthReport) => data.tipo_lavorazione_internet,
    },
    {
      title: 'Classe Internet',
      columnDef: 'classe_internet',
      accessor: (data: MonthReport) => data.classe_internet,
    },
    {
      title: 'data internet',
      columnDef: 'data_attivazione_internet',
      accessor: (data: MonthReport) => {
        return data.data_attivazione_internet ? DateTime.fromFormat(data.data_attivazione_internet, 'yyyy-LL-dd HH:mm:ss.u').toLocaleString(DateTime.DATE_SHORT) : '-';
      },
    }
  ];

  tableQuery?: ReportQuery;
  tableData: MonthReport[] = [];
  loading = false;
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private fileSaverService: FileSaverService,
    private matDialog: MatDialog) { 
  }

  ngOnInit(): void {
    this.authService.userInfo$.subscribe((userInfo: UserState) => {
      this.userStatus = userInfo;
      if(userInfo.isLoggedIn){
        this.initHomepage();
      }
    });
  }

  initHomepage(): void {
    const currentDate = DateTime.now().startOf('month');
    this.months.push({
      month: currentDate.toFormat('MM'),
      year: ""+currentDate.year,
      value: currentDate.monthLong + ' - ' + currentDate.year
    });
    for(let i = 1; i < 13; i++) {
      const currentMonth = currentDate.minus({month: i});
      this.months.push({
        month: ""+currentMonth.toFormat('MM'),
        year: ""+currentMonth.year,
        value: currentMonth.monthLong + ' - ' + currentMonth.year
      });
    }


    this.loadData(currentDate.toFormat('MM'), ""+currentDate.year);
  }


  onSelectionChange(change: MatSelectChange): void {
    this.loadData(change.value.month, change.value.year);
  } 


  loadData(month: string, year: string): void {
    this.loading = true;
    this.apiService.find({query: {month, year}} as FilterDTO<ReportQuery>).pipe(
      tap((res: HttpResponse<MonthReport[]>) => {
        if(res.body) {
          this.tableData = res.body;
        }
      }),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe();
  }

  downloadReport(): void {
    const currentDate = DateTime.now().startOf('month');
    this.apiService.downloadReport().subscribe((res: Blob) => {
      this.fileSaverService.save(res, `report_${currentDate.toFormat('MM')}${currentDate.year}.xlsx`);
    });
  }


  openUploadFileDialog(): void {
    let dialogRef = this.matDialog.open(UploadFileComponent, {
      height: '400px',
      width: '600px',
    });
  }

  logout(): void {
    this.authService.logout();
  }
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FileIconComponent } from '../file-icon/file-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLabel } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { DateTime } from 'luxon';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-file-attachments-input',
  templateUrl: './file-attachments-input.component.html',
  imports: [CommonModule, MatTooltipModule, ReactiveFormsModule, MatLabel, FileIconComponent],
  standalone: true
})
export class FileAttachmentsInputComponent {
  file: File | null = null;
  formGroup: FormGroup = this.fb.group({
    'fileToUpload': [null, Validators.required]
  });
  
  @Output() newItemEvent = new EventEmitter<{selectedElement:File[], controlName:string}>();


  constructor(
    protected fb: FormBuilder,
    private snackbarService: SnackBarService
  ) {
    
  }

  @HostListener("dragover", ["$event"]) 
  public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // @HostListener("dragleave", ["$event"]) 
  // public onDragLeave(evt: DragEvent): void {
  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }

  @HostListener('drop', ['$event'])
   public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if(!evt.dataTransfer || evt.dataTransfer.files.length === 0){
      // NECESSARIO CARICARE UN FILE
      this.snackbarService.snackBarError("E' necessario caricare un file Excel");
      return;
    }
    if(evt.dataTransfer.files.length > 1) {
      // ERRORE CARICARE UN SOLO FILE
      this.snackbarService.snackBarError("E' possibile caricare un solo file per volta");
    }

    const file = evt.dataTransfer.files[0];
    
    if(!this.validateFile(file)){
      return;
    }
    
    
    
    if(evt.dataTransfer!==null){
      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
        this.file =  evt.dataTransfer.files[i];
      }
      // this.newItemEvent.emit({selectedElement:this.files, controlName:this.controlName});
    }
  }


  somethingChanged(event:any):void{
    if(event!==null && event.target.files.length > 0){
      const file = event.target.files[0];
      if(!this.validateFile(file)){
        return;
      }

      this.file = file;
      // this.newItemEvent.emit({selectedElement:this.files, controlName:this.controlName})
    }
  }

  validateFile(file: File): boolean {
    if(!file.name.startsWith("cessazioni_pending_")) {
      // Messaggio per dire all'utente che il nome del file deve avere la forma cessazioni_pending_{MESE_CORRENTE}{ANNO_CORRENTE}
      this.snackbarService.snackBarError("Il formato del nome del file non è valido. La forma corretta è cessazioni_pending_{MESE_CORRENTE}{ANNO_CORRENTE}.xlsx. Ad esempio: cessazioni_pending_012024");
      return false;
    }

    const datePart = file.name.substring("cessazioni_pending_".length).replace(/.xls[x]?$/, "");
    if(datePart.length != 6) {
      // Messaggio per dire all'utente che il nome del file deve avere la forma cessazioni_pending_{MESE_CORRENTE}{ANNO_CORRENTE}
      this.snackbarService.snackBarError("Il formato del nome del file non è valido. La forma corretta è cessazioni_pending_{MESE_CORRENTE}{ANNO_CORRENTE}.xlsx. Ad esempio: cessazioni_pending_012024");
      return false;
    }

    const monthPart = datePart.substring(0, 2);
    const yearPart = datePart.substring(2);

    if(!DateTime.fromObject({month: +monthPart, year: +yearPart}).isValid) {
      // Non è una data valida
      this.snackbarService.snackBarError("E' possibile caricare solo un file relativo al mese corrente");
      return false;
    }

    if(DateTime.now().toFormat("MM") !== monthPart || DateTime.now().year != +yearPart) {
      // Il mese non è quello corrente
      this.snackbarService.snackBarError("E' possibile caricare solo un file relativo al mese corrente");
      return false;
    }

    return true
  }

  removeFile(event:any, file:File):void{
    event.preventDefault();
    event.stopPropagation();
    this.file = null;
    // this.newItemEvent.emit({selectedElement: this.files, controlName:this.controlName});
  }


}

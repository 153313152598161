import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

// import { FileDTO } from '../../model/DTO/FileDTO';

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
  standalone: true,
  imports: [
    NgIf,
    MatIconModule
  ]
})
export class FileIconComponent {
  @Input() name: string = '';
  @Input() type: string = '';

  // @Input() file: File | FileDTO | undefined; 


  // icon find 
  // Material Filled -> Files -> Office
  // es pdf= https://icons8.com/icon/set/pdf/material--static--red

  // controllare i formati
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  translateTypeToIcon(type:string):string{
    let icon="doc";
    if(type==='application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    type==='application/msword' ||
    type==='application/vnd.oasis.opendocument.text'){
      icon="doc";
    }else if(type==='application/pdf'){
      icon="pdf";
    }else if(type==='application/vnd.ms-excel' ||
      type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      type==='application/vnd.oasis.opendocument.spreadsheet' ||
      type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      type==='text/csv'){
      icon="xls";
    }
    else if(type==='image/png' ||
      type==='image/svg+xml' ||
      type==='image/tiff' ||
      type==='image/jpeg' ||
      type==='image/vnd.microsoft.icon'){
      icon="png";
    }else{
      icon="file";
    }
    return icon; 
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, ReplaySubject, catchError, map, of, tap } from 'rxjs';

import { JwtTokenResponse, UserInfoResponse } from '../models/api.model';
import { JWT_TOKEN_EXPIREDATE, JWT_TOKEN_NAME, User, UserState } from '../models/state';
import { ApiService } from './api.service';
import { CurrentUrlService } from './current-url.service';
import { LocalStorageService } from './local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DateTime } from 'luxon';

@Injectable({providedIn: 'root'})
export class AuthService {

    userInfo$: Observable<UserState>;
    private userInfo = new ReplaySubject<UserState>(1);

    // private userInfo = signal({user: null, isLoggedIn: false} as AppState);

    constructor(
        private storageService: LocalStorageService,
        private currentUrlService: CurrentUrlService,
        private apiService: ApiService,
        private router: Router) {
        this.userInfo$ = this.userInfo.asObservable();
        this.userInfo.next({user: null, isLoggedIn: false});
    }

    initAuth(): Observable<UserState> {
        const jwtToken = this.storageService.getItem(JWT_TOKEN_NAME);
        const jwtTokenExpireDate = this.storageService.getItem(JWT_TOKEN_EXPIREDATE);
        if(!jwtToken || !jwtTokenExpireDate) {
            const tokenParam = this.currentUrlService.getTokenParamFromCurrentUrl();
            if(!tokenParam) {
                this.startAuthentication();
                return EMPTY;
            }

            this.retrieveAndSaveJwtToken(tokenParam).subscribe(() => {
                this.retrieveAuthenticatedUserInfo();
                // remove token from url
                this.router.navigateByUrl("/");
            });

            
        } else {
            if(DateTime.fromISO(jwtTokenExpireDate) <= DateTime.now()) {
                this.restartAuthentication();
            } else {
                this.retrieveAuthenticatedUserInfo();
            }
        }


        return EMPTY;
    }

    restartAuthentication(): void {
        this.storageService.removeItem(JWT_TOKEN_NAME);
        this.storageService.removeItem(JWT_TOKEN_EXPIREDATE);
        this.startAuthentication();
    }

    retrieveAuthenticatedUserInfo(): void {
        this.apiService.getUserInfo().pipe(
            catchError((err: HttpErrorResponse) => {
                if(err.status.toString().startsWith('40')) {
                    this.restartAuthentication();
                }

                return EMPTY;
            }),
            tap((loggedUser: UserInfoResponse) => {
                this.userInfo.next({
                    isLoggedIn: true,
                    user: {
                        firstName: loggedUser.name,
                        lastName: loggedUser.lastname
                    }
                });
            })
        ).subscribe();
    }

    retrieveAndSaveJwtToken(token: string): Observable<JwtTokenResponse> {
        return this.apiService.getJwtToken(token).pipe(
            tap(jwtTokenRes => {
                this.storageService.setItem(JWT_TOKEN_NAME, jwtTokenRes.token);
                this.storageService.setItem(JWT_TOKEN_EXPIREDATE, jwtTokenRes.expiresIn);
            })
        );
    }

    startAuthentication(): void {
        this.apiService.login(environment.authRedirectUrl).pipe(
            tap(res => {
                window.location.href = res.redirect_url;
            })
        ).subscribe();
    }


    logout(): void {
        this.storageService.removeItem(JWT_TOKEN_NAME);
        window.location.href= `${environment.logoutStartUrl}/adfs/ls/idpinitiatedsignon?client-request-id=f27a9cde-b1f2-4a10-220a-0040010000dd`;
        /*
        this.storageService.removeItem(JWT_TOKEN_NAME);
        var i = document.createElement('iframe');
        i.style.display = 'none';
        i.onload = function() {
            i.parentNode?.removeChild(i);
            window.location.reload();
        };

        i.src = 'https://sepintesa-test.opensymbol.it:4443/webprovisioning/Saml/SamlLogout.aspx';
        document.body.appendChild(i);
        */
    }
}
<form [formGroup]="formGroup" class="row dropbox display-cursor display-flex align-items-center justify-content-center" >
    <label class="input-custom-file mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent color-black" >
        <div (click)="hiddenfileinput.click()" (dropped)="somethingChanged($event)" *ngIf="!file">
            <input formControlName="fileToUpload" class="display-none" type="file" multiple="false" (change)="somethingChanged($event)" #hiddenfileinput>
            <button mat-button color="primary" class="waves-effect waves-light btn"><i
                class="material-icons left">attach_file</i>
                {{'Trascina il file oppure clicca qui'}}
            </button>
        </div>
        
        <div *ngIf="file">
            <app-file-icon 
 
                (click)="removeFile($event, file)" 
                [name]="file.name"
                [type]="file.type"
                matTooltip="{{'text.removeFile'}}"
                [matTooltipPosition]="'above'">              
            </app-file-icon>
            
        </div>
        
    </label>
</form>



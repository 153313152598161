import { CdkTableModule } from '@angular/cdk/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileSaverModule } from 'ngx-filesaver';

import { AppComponent } from './app.component';
import { TableComponent } from './components/table/table.component';
import { TitleComponent } from './components/title/title.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthService } from './services/auth.service';

const PERSONAL_MAT_LUXON_DATE_FORMATS: MatDateFormats = {
  display: {
      dateA11yLabel: "DD",
      dateInput: "dd/LL/yyyy",
      monthYearA11yLabel: "LLLL yyyy",
      monthYearLabel: "LLL yyyy"
  },
  parse: {
      dateInput: "dd/LL/yyyy"
  }
}

@NgModule({
    declarations: [
      AppComponent,
      TitleComponent,
      TableComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatCardModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        OverlayModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatSelectModule,
        MatLuxonDateModule,
        MatDialogModule,
        MatSnackBarModule,
        CdkTableModule,
        FileSaverModule
    ],
    providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: (authService: AuthService) => () => authService.initAuth(),
        deps: [AuthService],
        multi: true
      },
      { provide: MAT_DATE_FORMATS, useValue: PERSONAL_MAT_LUXON_DATE_FORMATS },
      { provide: LOCALE_ID, useValue: 'it-IT' },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
  })
  export class AppModule { }
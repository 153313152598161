import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonTitle } from '../../models/buttonTitle';
import { MatSelectChange } from '@angular/material/select';
import { MonthYearOption } from '../../models/selectOption.model';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html'
})
export class TitleComponent implements OnInit {
  @Input() title = '';
  @Input() buttons: ButtonTitle[] = [];
  @Input() options: MonthYearOption[] = [];

  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  selectedOption: MonthYearOption | null = null;
  ngOnInit(): void {
      this.selectedOption = this.options[0];
  }


  onSelectionChange(change: MatSelectChange): void {
    this.selectionChange.emit(change);
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from '../../environments/environment';
import { JWT_TOKEN_NAME } from '../models/state';

/**
 * Http Interceptor class that add access token to any request that requires it
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storageService: LocalStorageService,
    ) {}

  /**
   * Interceptor that add bearer token to requests
   * @param request The http request
   * @param next used for binding to http request stream
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
  if ( !request 
       || !request.url 
       || ( request.url.startsWith('http') 
            && !( environment.apiUrl 
            && request.url.startsWith(environment.apiUrl))
        )
       || request.url.includes("/login")
       || request.url.includes("/gateway")
    ) {
      return next.handle(request);
    }

    const token = this.storageService.getItem(JWT_TOKEN_NAME);
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
    }
    return next.handle(request);
  }
}

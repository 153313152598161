<mat-card class="margin-10-px">
    <mat-card-content>
        <div class="display-flex justify-content-space-between">
            <div class="display-flex align-items-center">
                <span class="flex-shrink-0" *ngIf="title">{{title}}</span>
                <mat-form-field class="padding-left-16-px">
                <mat-select [(value)]="selectedOption" (selectionChange)="onSelectionChange($event)">
                    @for (soption of options; track soption) {
                      <mat-option [value]="soption">{{soption.value| titlecase}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            </div>
            <div>
                <div class="display-flex">
                    <ng-container *ngFor="let button of buttons">
                        <button mat-raised-button color="accent" class="margin-left-10-px" (click)="button.actionButton()">{{button.lable}}</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>


<div class="mat-elevation-z8">
  <div class="table-responsive">
    <table mat-table [dataSource]="tableDataSource" matSort>
      <div class="spinner-container"><mat-spinner></mat-spinner></div>
      <ng-container *ngFor="let table of tableColumn">
        <ng-container [matColumnDef]="table.columnDef" *ngIf="table.columnDef!=='other'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" [disabled]="table.isSortable === false"> {{table.title}}</th>
          <ng-container *ngIf="table.tooltip == undefined" >
            <td mat-cell *matCellDef="let element" class="{{table.accessorClass ? table.accessorClass(element) : ''}}"> 
              <ng-container>
                {{table.accessor(element)}} 
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngIf="table.tooltip !== undefined && table.tooltipClass!== undefined" >
            <td mat-cell matTooltipClass={{table.tooltipClass(element)}} *matCellDef="let element" class="{{table.accessorClass ? table.accessorClass(element) : ''}}" matTooltip="{{table.tooltip(element)}}" [matTooltipPosition]="'above'"> 
              <ng-container>
                {{table.accessor(element)}} 
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <ng-container [matColumnDef]="table.columnDef" *ngIf="table.columnDef==='other'" stickyEnd>
          <th mat-header-cell *matHeaderCellDef> {{table.title}}</th>
          <td mat-cell *matCellDef="let element" class="{{table.accessorClass ? table.accessorClass(element) : ''}}"> 
            <div class="display-flex justify-content-space-evenly">
              <ng-container *ngFor="let icon of tableIcon">
                <button mat-icon-button [disabled]="icon.isEnabled?.(element) === false"
                  (click)="icon.actionIcon(element)"
                  matTooltip="{{icon.tooltip}}"
                  [matTooltipPosition]="'above'">
                  <mat-icon>
                    {{icon.icon}}
                  </mat-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:false" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="reload"></tr>
      <tr [ngClass]="{'mat-row': true, 'table-nodata': true, 'table-nodata--hidden': reload}" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns?.length">
          <div class="table-nodata_content">
            No data to show
          </div>
        </td>
      </tr>
    </table>
    <div class="table-spinner-container" *ngIf="reload">
        <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
    aria-label="Select page">
  </mat-paginator>
</div>

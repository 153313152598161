import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar,
    private auth: AuthService
    ) { }

  snackBarSuccess(messaggio:string):MatSnackBarRef<TextOnlySnackBar>{
    return this.snackBar.open(messaggio,"", {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }

  snackBarError(messaggio:string):MatSnackBarRef<TextOnlySnackBar>{
    return this.snackBar.open(messaggio,"Close", {
      duration: 4000,
      panelClass: ['error-snackbar']
    });
  }

  snackBarWarning(messaggio:string):MatSnackBarRef<TextOnlySnackBar>{
    return this.snackBar.open(messaggio,"Close", {
      duration: 4000,
      panelClass: ['warn-snackbar']
    });
  }

}

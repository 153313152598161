import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { DownloadFileResponse, JwtTokenResponse, LoginResponse, UserInfoResponse } from '../models/api.model';
import { FilterDTO, ReportQuery } from '../models/FilterDTO.model';
import { MonthReport } from '../models/monthReport.model';
import { IQueryService } from '../models/query.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ApiService implements IQueryService<ReportQuery, MonthReport> {
    private apiUrl: string;

    constructor(private http: HttpClient) {

        this.apiUrl = environment.apiUrl;
    }

    public login(successUri?: string): Observable<LoginResponse> {
        let headers = new HttpHeaders();
        if(successUri) {
            headers = headers.append("Success-Uri", successUri);
        }
        return this.http.get<LoginResponse>(`${environment.apiUrl}/login`, {headers});
    }


    public getJwtToken(token: string): Observable<JwtTokenResponse> {
        let params = new HttpParams();
        params = params.append('token', token);
        return this.http.get<JwtTokenResponse>(`${this.apiUrl}/gateway`, {params});
    }

    public getUserInfo(): Observable<UserInfoResponse> {
        return this.http.get<UserInfoResponse>(`${this.apiUrl}/me`);
    }

    public find(filter: FilterDTO<ReportQuery>): Observable<HttpResponse<MonthReport[]>> {
        let params = new HttpParams({fromObject: {
            ...filter.query
        }});
        return this.http.get<MonthReport[]>(`${this.apiUrl}/showrows`, {params: params, observe: 'response'});
    }

    public downloadReport(): Observable<Blob> {
        return this.http.get<DownloadFileResponse>(`${this.apiUrl}/downloadfile`).pipe(
            mergeMap((res: DownloadFileResponse) => {
                const httpOptions = {
                    responseType: 'blob' as 'json'
                  };
                return this.http.get<Blob>(res.url, httpOptions);
            }),
        );
    }

    public uploadTerminationsFile(termFile: File): Observable<HttpResponse<void>> {
        return this.http.post<void>(`${this.apiUrl}/upload`, termFile, {headers: new HttpHeaders({
            'X-File-Name': termFile.name
        }), observe: 'response'});
    }
}
<h2 mat-dialog-title>Carica file delle cessazioni</h2>
<mat-dialog-content>
    <app-file-attachments-input controlName="none"></app-file-attachments-input>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true" color="accent">Annulla</button>
    <button mat-raised-button class="margin-left-10-px" color="primary" (click)="uploadFile()">Invia</button>
</mat-dialog-actions>
<!--<mat-card class="height-perc-100">
    <mat-card-header>
        <mat-card-title>Carica file delle cessazioni</mat-card-title>
    </mat-card-header>
    <mat-card-content class="height-perc-100">
        <app-file-attachments-input controlName="none"></app-file-attachments-input>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-button>LIKE</button>
    </mat-card-actions>
</mat-card>-->
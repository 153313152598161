import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FileAttachmentsInputComponent } from '../file-attachments-input/file-attachments-input.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from '../../services/api.service';
import { EMPTY, catchError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../services/snack-bar.service';




@Component({
    selector: 'app-upload-file',
    templateUrl: './uploadFile.component.html',
    imports: [FileAttachmentsInputComponent, MatCardModule, MatButtonModule, MatDialogModule],
    standalone: true
  })
export class UploadFileComponent {
  @ViewChild(FileAttachmentsInputComponent) attachmentComponent?: FileAttachmentsInputComponent;

    constructor(
      public dialogRef: MatDialogRef<UploadFileComponent>,
      public apiService: ApiService,
      private snackBarService: SnackBarService) {
    }

    uploadFile() {
      if(this.attachmentComponent && this.attachmentComponent.file) {
        // continuare da qui
        this.apiService.uploadTerminationsFile(this.attachmentComponent.file).pipe(
          catchError((err: HttpErrorResponse) => {
            this.snackBarService.snackBarError(err.error.message ? `Impossibile caricare il file:${err.error.message}` : "Impossibile caricare il file: Errore generico");
            return EMPTY;
          })
        ).subscribe(res => {
          // MESSAGGIO DI SUCCESSO
          this.snackBarService.snackBarSuccess("Upload del file effettuato correttamente");

          this.dialogRef.close();
        });
      }
    }
}